export const environment = {
  production: false,
  local: false,
  api_url: 'https://emp-staging-api.deskware.com/',
  integration_api_url: 'https://integrations-api.staging.empist360.com/',
  local_url: 'https://staging.empist360.com',
  oauth_client_id: 'OvrYWoIRFn3HkW0_lZ74pqSzRw4sI6KKUg5mIrRgiBw',
  oauth_tenant: 'empist',
  oauth_url: 'https://emp-auth.staging.deskware.com/',
  enableAppcues: false,
  appcuesAccountId: '',
};
